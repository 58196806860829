
.hero-banner {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    min-height: 115px;
    animation: fadeIn ease 4s;

    @keyframes fadeIn{
        0% {
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    &-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;

        > .col {
            flex: 0 0 auto;
        }
    }

    &-image {
        position: absolute;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        overflow: hidden;

        > .advert {
            height: 100% !important;
            width: 100% !important;

            > div {
                height: 100% !important;
                width: 100% !important;

                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 0 auto;
        padding-top: 20px;
        width: 100%;
        z-index: 10;

        > * {
            flex-grow: 0;
        }
    }

    &-logo {
        align-self: flex-start;

        img {
            max-height: 50px;
        }
    }

    &-top {
        justify-content: space-between;

        & * {
            z-index: 10;
        }
    }

    .buttons-col {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .hero-banner-top-buttons {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            a.btn {
                // car of the week has inline styles that target .btn for some reason and give it margin
                margin: 0 !important;
            }

            a:not(.btn) {
                line-height: 35px;
            }
        }

        .constructionsales & .btn-secondary {
            background-color: #ed1c24;
            border-color: #ed1c24;

            &:hover {
                background-color: #de1219;
                border-color: #de1219;
            }
        }
    }

    &-bottom {
        position: absolute;
        justify-content: flex-start;
        left: 0;
        bottom: 0;
        width: auto;
        margin: 0 0 28px 28px;

        &-link {
            display: inline-flex;
            color: #fff;
            align-items: center;

            &:hover {
                text-decoration: none;

                .bottom-link-text {
                    text-decoration: underline;
                }
            }

            img {
                max-width: 40px;
                margin-right: 16px;
            }

            .bottom-link-text {
                margin-right: 6px;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                line-height: 15px;
            }

            .icon {
                width: 15px;
                height: 15px;

                &:before {
                    color: #fff;
                }
            }
        }
    }
}

.chileautos {
    .hero-banner {
        &-image {
            > .advert {
                div {

                    height: 100% !important;
                    width: 100% !important;
                }
            }
        }
    }
}